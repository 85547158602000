import React, { memo } from 'react'

import * as S from './styles'

export type SkeletonProps = DefaultHTMLAttrs<HTMLDivElement> & {
  width?: string
  height?: string
  animation?: S.AnimationTypes
  variant?: S.VariantTypes
}

const Component = ({
  width = '100%',
  height = '100%',
  animation = 'wave',
  variant = 'text',
  ...attrs
}: SkeletonProps) => {
  const ToRender = variant === 'circle' && animation === 'wave' ? S.CircleContainer : S.Container

  return (
    <ToRender width={width} height={height} animation={animation} variant={variant} {...attrs} />
  )
}

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/pages-utils-skeleton-since-v1-0-3--simple)
 *
 * **Code example**
 * ```tsx
 * import React from 'react'
 *
 * import { Skeleton } from '@interco/inter-ui/components/Skeleton'
 *
 * export const MyPage = () => {
 *   return (
 *     <Skeleton
 *       width="150px"
 *       height="150px"
 *       animation="wave"
 *     />
 *   )
 * }
 * ```
 */
export const Skeleton = memo(Component)
