import React, { useCallback, useState } from 'react'

import { uuid } from '@/utils/uuid'

import { NotificationShowRequest, NotificationSettings } from './types'
import { Notifications } from './notifications'
import { NotificationsContext } from './context'

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/feedback-notification-since-v1-0-5--all)
 *
 * **Code example**
 * ```tsx
 * import React from 'react'
 *
 * import { NotificationProvider } from '@interco/inter-ui/components/Notification'
 *
 * export const App = () => {
 *   return (
 *     <NotificationProvider>
 *
 *       <MyPage />
 *       {...}
 *
 *     </NotificationProvider>
 *   )
 * }
 * ```
 */
export const NotificationProvider = ({ children }: PropsWithRequiredChildren) => {
  const [notifications, setNotification] = useState<NotificationSettings[]>([])

  const handleCloseNotification = useCallback(
    (id: string) => {
      setNotification((prevState) => prevState.filter((notification) => notification.id !== id))
    },
    [setNotification],
  )

  const handleCloseAllNotifications = useCallback(() => {
    setNotification([])
  }, [setNotification])

  const handleShowNotification = useCallback(
    (settings: NotificationShowRequest | NotificationShowRequest[]) => {
      if (Array.isArray(settings)) {
        const newNotifications = settings.map((config) => ({
          ...config,
          time: config.time || 5 * 1000,
          id: uuid(),
        }))

        setNotification((prevState) => [...prevState, ...newNotifications])
        return
      }

      setNotification((prevState) => [
        ...prevState,
        {
          ...settings,
          id: uuid(),
          time: settings.time || 5 * 1000,
        },
      ])
    },
    [setNotification],
  )

  return (
    <NotificationsContext.Provider
      value={{
        closeNotification: handleCloseNotification,
        closeAllNotifications: handleCloseAllNotifications,
        showNotification: handleShowNotification,
        notifications,
      }}
    >
      {children}
      <Notifications />
    </NotificationsContext.Provider>
  )
}
