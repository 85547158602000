import React, { InputHTMLAttributes, memo } from 'react'

import * as S from './styles'

export type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  checked: boolean
  disabled?: boolean
  indeterminate?: boolean
  reversed?: boolean
  size?: number
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
} & ({ label?: never; id?: never } | { label?: string | React.ReactNode; id: string })

const Label: React.FC<Partial<CheckboxProps>> = ({ children, reversed }) => (
  <S.LabelText reversed={reversed}>{children}</S.LabelText>
)

const getCheckIcon = (indeterminate: boolean, checked: boolean) => {
  if (indeterminate) return <S.CheckboxIndeterminate />
  return checked ? <S.CheckboxOn /> : <S.CheckboxOff />
}

const Component = React.forwardRef(
  (
    {
      label = '',
      id,
      checked = false,
      defaultChecked,
      disabled = false,
      indeterminate = false,
      reversed = false,
      size = 32,
      onChange,
      ...attrs
    }: CheckboxProps,
    ref?: React.Ref<HTMLInputElement>,
  ) => (
    <S.LabelContainer disabled={disabled} htmlFor={id}>
      {reversed && label && <Label reversed={reversed}>{label}</Label>}

      <S.Input
        {...attrs}
        id={id}
        type="checkbox"
        ref={ref}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={onChange}
        checked={checked}
      />

      <S.StyledCheckbox size={size}>{getCheckIcon(indeterminate, checked)}</S.StyledCheckbox>

      {!reversed && label && <Label reversed={reversed}>{label}</Label>}
    </S.LabelContainer>
  ),
)

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/forms-checkbox-since-v1-0-0--simple)
 * - [`Figma Orange DS Documentation`](https://www.figma.com/file/7QVC2u96bJmyW8qQ8mBNb3/DS-%2F-Components-%2F-Global?node-id=8555-3460&t=xRB8dh4L23EYezaX-0)
 *
 * **Code example**
 * ```tsx
 * import React, { useState } from 'react'
 *
 * import { Checkbox } from '@interco/inter-ui/components/Checkbox'
 *
 * export const MyPage = () => {
 *   const [checked, setChecked] = useState<boolean>(false)
 *
 *   return (
 *     <Checkbox
 *       checked={checked}
 *       onChange={() => setChecked(!checked)}
 *     />
 *   )
 * }
 * ```
 */
export const Checkbox = memo(Component)
