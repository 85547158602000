import { useContext } from 'react'

import { NotificationsContext } from './context'

/**
 * - [`Inter UI Documentation`](https://inter-ui.bancointer.com.br/?path=/story/feedback-notification-since-v1-0-5--all)
 *
 * **Code example**
 * ```tsx
 * import React from 'react'
 *
 * import { NotificationProvider, useNotification } from '@interco/inter-ui/components/Notification'
 * import { Button } from '@interco/inter-ui/components/Button'
 *
 * @attention
 * // Para utilizar as notificações você precisa adicionar, 'NotificationProvider' no seu projeto.
 *
 * export const App = () => {
 *   return (
 *     <NotificationProvider>
 *
 *       <MyPage />
 *       {...}
 *
 *     </NotificationProvider>
 *   )
 * }
 *
 * export const MyPage = () => {
 *   const { showNotification } = useNotification()
 *
 *   return (
 *     <>
 *       <Button
 *         onClick={() =>
 *           showNotification({
 *             title: 'Title notification success',
 *             content: 'Content notification',
 *             type: 'SUCCESS',
 *           })
 *         }
 *       >
 *         Open Notification
 *       </Button>
 *     </>
 *   )
 * }
 * ```
 */
export const useNotification = () => useContext(NotificationsContext)
