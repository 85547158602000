import { createContext } from 'react'

import { NotificationSettings, NotificationShowRequest } from './types'

type NotificationsContext = {
  closeNotification: (id: string) => void
  closeAllNotifications: () => void
  showNotification: (settings: NotificationShowRequest | NotificationShowRequest[]) => void
  notifications: NotificationSettings[]
}

export const NotificationsContext = createContext<NotificationsContext>({} as NotificationsContext)
