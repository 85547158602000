export const COMMON_MASKS = {
  CPF: '999.999.999-99',
  CNPJ: '99.999.999/9999-99',
  CEP: '99999-999',
  CELLPHONE: '(99) 99999-9999',
  LANDLINE: '(99) 9999-9999',
  BR_DATE: '99/99/9999',
}

export const dynamicMask = (masks: string[], value: string): string => {
  const ascendingOrder = masks.sort((a, b) => a.length - b.length)

  const biggestMask = ascendingOrder[masks.length - 1]
  const closerMask = ascendingOrder.find((maskItem) => value.length <= maskItem.length) as string

  const closerMaskFilled = closerMask.split('')

  if (closerMaskFilled.length < biggestMask.length) {
    const countToFill = biggestMask.length - closerMaskFilled.length

    for (let index = 0; index < countToFill; index += 1) {
      closerMaskFilled.push('9')
    }
  }

  return closerMaskFilled.join('')
}
