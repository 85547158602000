import React, { useEffect, useMemo, useRef, useState, useCallback } from 'react'

import * as S from './styles'
import { NotificationSettings } from './types'

const REMOVE_DELAY = 200 as number | undefined

export const Notification = ({
  type,
  title,
  content,
  time,
  onClick,
  onRemove,
  closeNotification,
}: NotificationSettings & { closeNotification: () => void }) => {
  const [show, setShow] = useState<boolean>(false)
  const [hide, setHide] = useState<boolean>(false)
  const [height, setHeight] = useState<number>(0)
  const timeout = useRef<Timeout>()
  const isMounted = useRef<boolean>(false)
  const notificationRef = useRef<HTMLDivElement>(null)

  const getIcon = useMemo(
    () => ({
      NULL: <S.Insert data-testid="square-icon" />,
      SUCCESS: <S.CheckFill data-testid="success-icon" />,
      ERROR: <S.Exit data-testid="error-icon" />,
      ALERT: <S.Attention data-testid="alert-icon" />,
    }),
    [],
  )

  const close = useCallback(() => {
    if (!isMounted.current) return

    timeout.current && clearTimeout(timeout.current)
    setShow(false)
    setHide(true)

    timeout.current = setTimeout(() => {
      onRemove?.()
      closeNotification()
    }, REMOVE_DELAY)
  }, [closeNotification, onRemove])

  const showNotification = useCallback(() => {
    setHeight(notificationRef.current?.offsetHeight || 0)

    setShow(true)
    timeout.current = setTimeout(() => {
      close()
    }, time)
  }, [close, time])

  useEffect(() => {
    isMounted.current = true
    if (time && !!closeNotification) {
      showNotification()
    }
    return () => {
      isMounted.current = false
    }
  }, [closeNotification, showNotification, time])

  return (
    <S.Container
      onClick={onClick}
      ref={notificationRef}
      type={type}
      show={show}
      hide={hide}
      marginBottom={-height}
      role="dialog"
    >
      <S.Icon>{getIcon[type]}</S.Icon>

      <S.Message>
        <S.Title>{title}</S.Title>
        <S.Content>{content}</S.Content>
      </S.Message>

      <S.ClearSection>
        <S.Clear
          data-testid="clear-icon"
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation()
            close()
          }}
        />
      </S.ClearSection>
    </S.Container>
  )
}
