import React from 'react'

import * as S from './styles'
import { useNotification } from './useNotification'
import { Notification } from './notification'

export const Notifications = () => {
  const { notifications, closeNotification } = useNotification()

  return (
    <S.Notifications>
      {notifications.map((notification) => (
        <Notification
          {...notification}
          closeNotification={() => closeNotification(notification.id)}
          key={notification.id}
        />
      ))}
    </S.Notifications>
  )
}
